import React, { useEffect, useState } from "react";

const HeroSection = () => {
  const words = ["words", "images", "colors", "buttons", "combinations"];
  const [currentWordIndex, setCurrentWordIndex] = useState(0);
  const [fadeClass, setFadeClass] = useState("opacity-100");

  useEffect(() => {
    const cycleWords = setInterval(() => {
      setFadeClass("opacity-0");

      setTimeout(() => {
        setCurrentWordIndex((prevIndex) => (prevIndex + 1) % words.length);
        setFadeClass("opacity-100");
      }, 1000);
    }, 5000);

    return () => clearInterval(cycleWords);
  }, [words.length]);

  return (
    <section className="flex flex-col items-center justify-center text-gray-800 px-10">
      {/* Main Content */}
      <div className="w-full max-w-3xl text-center">
        <h1 className="text-4xl md:text-5xl px-5">
          Automatically test content that drives most sales
        </h1>
        <p className="text-lg md:text-xl text-black my-8 px-10">
          Boost conversions effortlessly — The adaptive test automation
          optimizes your site for success.
        </p>

        {/* CTA */}
        <div className="flex flex-col items-center gap-4">
          <button className="bg-black hover:bg-gray-900 text-white text-md font-semibold px-8 py-3 rounded-3xl">
            Get Started Today
          </button>
          <p className="text-sm text-gray-400">No credit card required</p>
        </div>
      </div>

    </section>
  );
};

export default HeroSection;
