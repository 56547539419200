import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFont, faHandPointer, faPhotoVideo, faPaintBrush } from "@fortawesome/free-solid-svg-icons";

const ShowcaseSection = () => {
  return (
    <section className="w-full bg-[#FAF9F6] py-12 px-12 md:px-8 flex justify-center">
      {/* Parent Card */}
      <div className="mt-12 w-full max-w-3xl">
        <div className="bg-white border-4 border-purple-600 shadow-md rounded-lg p-6">
          {/* Title */}
          <h3 className="text-lg md:text-xl font-semibold text-gray-800 text-center">
            What Do You Want to Optimize?
          </h3>

          {/* Options Row */}
          <div className="flex flex-wrap justify-center gap-4 mt-6">
            {/* Option: Headlines */}
            <div className="flex flex-col items-center justify-center bg-gray-100 border rounded-lg shadow-sm hover:shadow-lg cursor-pointer transition w-36 h-36">
              <div className="text-2xl text-gray-500 mb-4">
                <FontAwesomeIcon icon={faFont} />
              </div>
              <p className="text-center text-lg text-gray-800">Headlines</p>
            </div>

            {/* Option: Buttons */}
            <div className="flex flex-col items-center justify-center bg-gray-100 border rounded-lg shadow-sm hover:shadow-lg cursor-pointer transition w-36 h-36">
              <div className="text-2xl text-gray-500 mb-4">
                <FontAwesomeIcon icon={faHandPointer} />
              </div>
              <p className="text-center text-lg text-gray-800">Buttons</p>
            </div>

            {/* Option: Images */}
            <div className="flex flex-col items-center justify-center bg-gray-100 border rounded-lg shadow-sm hover:shadow-lg cursor-pointer transition w-36 h-36">
              <div className="text-2xl text-gray-500 mb-4">
                <FontAwesomeIcon icon={faPhotoVideo} />
              </div>
              <p className="text-center text-lg text-gray-800">Images</p>
            </div>

            {/* Option: Styling */}
            <div className="flex flex-col items-center justify-center bg-gray-100 border rounded-lg shadow-sm hover:shadow-lg cursor-pointer transition w-36 h-36">
              <div className="text-2xl text-gray-500 mb-4">
                <FontAwesomeIcon icon={faPaintBrush} />
              </div>
              <p className="text-center text-lg text-gray-800">Styling</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ShowcaseSection;
